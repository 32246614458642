import { mapActions, mapMutations, mapState } from 'vuex'
import { recaptcha } from '../../../mixins/recaptcha'
import { CODE } from '../../../globals'
import { getStepFromRouterParams } from './support'

export default {
  name: 'CompanyReport',
  mixins: [recaptcha],

  components: {
    ReportForm: () => import('../../Reports/ReportForm'),
  },

  data() {
    const { params } = this.$router.currentRoute
    const { anonymous } = params

    return {
      ready: params.agree || false,
      anonymous: anonymous || false,
      preReport: null,
      step: getStepFromRouterParams({ ...params }),
    }
  },

  beforeCreate() {
    const { agree = false, slug, slugId } = this.$router.currentRoute.params

    if (!agree) {
      this.$router.push({ name: 'company', params: { slug, slugId } })
    }
  },

  beforeMount() {
    const { recover } = this.$router.currentRoute.params
    const code = localStorage.getItem(CODE)

    if (recover && code) {
      const token = localStorage.getItem(code)
      this.decodePreReport(token)
        .then(({ payload }) => (this.preReport = payload))
        .finally(() => {
          localStorage.removeItem(code)
          localStorage.removeItem(CODE)
        })
    }
  },

  mounted() {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  },

  computed: mapState(['company']),

  methods: {
    ...mapActions(['createReport', 'decodePreReport']),
    ...mapMutations(['setError']),

    async submit(payload) {
      const report = await this.createReport(payload)
      const { match: connect } = payload
      const { slug, slugId } = this.company

      if (!report?.id) return

      if (report?.anonymous) {
        this.$router.push({
          name: 'company-report-subscribe',
          params: {
            slug,
            slugId,
          },
        })
      } else {
        const successRoute = slug ? 'company-report-success' : 'report-success'

        this.$router.push({
          name: successRoute,
          params: {
            slug,
            slugId,
            connect,
            code: report.code,
          },
        })
      }
    },
  },
}
